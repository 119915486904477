import classNames from "classnames";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { ReactNode } from "react";
import SwiperCore from "swiper";

import Container from "~/components/common/container";
import HighlightedText from "~/components/common/highlighted-text";
import Icon from "~/components/common/icon";
import Editorials from "~/types/editorials";
import appRoutes from "~/utils/app-routes";
import contentfulUtils from "~/utils/contentful-utils";

import styles from "./carousel-utils.module.scss";

export function updateArrowStatus(instance: SwiperCore, isInfiniteScroll?: boolean) {
  return {
    arrowL: isInfiniteScroll || !instance.isBeginning,
    arrowR: isInfiniteScroll || !instance.isEnd,
  };
}

type WrapperProps = {
  children: ReactNode;
  entry?: Entry<
    Editorials.ServicesCarousel | Editorials.ProductCarousel | Editorials.LinksCarousel | Editorials.BrandCarousel
  >;
};

export function CarouselWrapper({ children, entry }: WrapperProps) {
  const router = useRouter();
  if (entry) {
    const inspectorMode = contentfulUtils.useInspectorMode(entry);
    const isCartPage = router.pathname === appRoutes.CART;

    return (
      <div className={classNames(styles.container, styles[entry.fields.backgroundColor])}>
        {"title" in entry.fields && entry.fields.title && (
          <h2 {...inspectorMode?.getProps("title")} className={isCartPage ? styles.cartTitle : styles.title}>
            <HighlightedText text={entry.fields.title} />
          </h2>
        )}
        <Container className={styles.relativeContainer}>{children}</Container>
      </div>
    );
  } else {
    return <Container className={styles.relativeContainer}>{children}</Container>;
  }
}

type ArrowsProps = {
  swiperInstance: SwiperCore;
  arrowsStatus: {
    arrowL: boolean;
    arrowR: boolean;
  };
  className?: string;
};

export function CarouselArrows({ swiperInstance, arrowsStatus, className }: ArrowsProps) {
  const t = useTranslations();
  return (
    <div className={classNames(styles.absoluteContainer, className)}>
      <button
        className={styles.arrowL}
        disabled={!arrowsStatus.arrowL}
        onClick={() => swiperInstance.slidePrev()}
        aria-label={t("a11y.previousSlide")}
      >
        <Icon name="chevron-left" width={24} height={24} />
      </button>
      <button
        className={styles.arrowR}
        disabled={!arrowsStatus.arrowR}
        onClick={() => swiperInstance.slideNext()}
        aria-label={t("a11y.nextSlide")}
      >
        <Icon name="chevron-right" width={24} height={24} />
      </button>
    </div>
  );
}
